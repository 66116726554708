import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader } from 'lucide-react';

const DripCourseSignup = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [emailValid, setEmailValid] = useState(true);
  const navigate = useNavigate();
  const { courseId } = useParams();

  const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setEmailValid(false);
      return;
    }
    setLoading(true);
    setError(null);
    
    try {
      const response = await fetch('/api/drip-courses/subscribe', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, courseId })
      });
      const data = await response.json();

      if (data.status === 'success') {
        navigate(`/courses/${courseId}/signup-success`, { state: { email } });
      } else if (data.status === 'existing_access') {
        navigate(`/courses/${courseId}/signup-success`, { state: { email, existing: true } });
      }
    } catch (err) {
      setError('Oops! Something went wrong. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto mt-16 p-8 bg-white rounded-lg shadow-xl transition-all">
      <h1 className="text-3xl font-extrabold text-gray-900 mb-6">
        🚀 Get Early Access Now!
      </h1>
      <p className="text-lg text-gray-600 mb-8">
        Enter your email to receive the first module and stay updated.
      </p>

      {error && (
        <div className="bg-red-100 text-red-700 p-4 rounded-md mb-6">
          {error}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label htmlFor="email" className="block text-md font-medium text-gray-700">
            Email Address
          </label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailValid(true);
            }}
            placeholder="you@example.com"
            className={`w-full p-3 border rounded-md transition ${
              emailValid ? 'border-gray-300' : 'border-red-500'
            }`}
            required
          />
          {!emailValid && (
            <p className="text-sm text-red-500 mt-1">
              Please enter a valid email address.
            </p>
          )}
        </div>

        <button
          type="submit"
          disabled={loading}
          className={`w-full flex items-center justify-center gap-2 bg-purple-600 text-white py-3 px-6 rounded-md hover:bg-purple-700 disabled:opacity-50 transition-all`}
        >
          {loading ? <Loader className="w-5 h-5 animate-spin" /> : 'Get Access'}
        </button>
      </form>
    </div>
  );
};

export default DripCourseSignup;
