import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams, useSearchParams } from 'react-router-dom';
import { PlayCircle, BookOpen, Clock, ChevronRight, CheckCircle, RefreshCw, Briefcase, Users, Award } from 'lucide-react';
import useApi from '../hooks/useApi';

const includedItems = [
  { text: 'Instant access to initial modules', icon: <CheckCircle className="w-6 h-6 text-purple-600" /> },
  { text: 'Regular content updates', icon: <RefreshCw className="w-6 h-6 text-purple-600" /> },
  { text: 'Practical exercises and case studies', icon: <Briefcase className="w-6 h-6 text-purple-600" /> },
  { text: 'Exclusive course community access', icon: <Users className="w-6 h-6 text-purple-600" /> },
  { text: 'Certificate of Completion', icon: <Award className="w-6 h-6 text-purple-600" /> }
];

const DripCourseWelcome = () => {
  const { loginWithRedirect } = useAuth0();
  const { courseId } = useParams();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');

  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { fetchCourseDetail } = useApi();

  useEffect(() => {
    const loadCourse = async () => {
      try {
        const fetchedCourse = await fetchCourseDetail(courseId);
        setCourse(fetchedCourse);
      } catch (err) {
        console.error('Error loading course:', err);
        setError('Failed to load course details.');
      } finally {
        setLoading(false);
      }
    };
    loadCourse();
  }, [courseId, fetchCourseDetail]);

  const handleStartLearning = () => {
    loginWithRedirect({
      appState: {
        returnTo: `/courses/${courseId}/drip-content?link=true&email=${encodeURIComponent(email)}`
      },
      login_hint: email
    });
  };

  if (loading) return <div className="text-center">Loading...</div>;
  if (error) return <div className="text-center text-red-500">{error}</div>;
  if (!course) return <div className="text-center">Course not found.</div>;

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white flex justify-center items-center px-4">
      <div className="max-w-4xl bg-white p-12 rounded-2xl shadow-2xl transform transition-all duration-500 hover:scale-105">
        <div className="text-center mb-12">
          <h1 className="text-5xl font-extrabold text-gray-900 mb-4">
            🚀 Welcome to {course.title}
          </h1>
          <p className="text-xl text-gray-600">
            {course.description}
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-10">
          <div className="space-y-8">
            <div className="flex items-start gap-4">
              <div className="p-4 bg-purple-100 rounded-lg">
                <PlayCircle className="w-8 h-8 text-purple-600" />
              </div>
              <div>
                <h3 className="text-2xl font-semibold">Start Learning Now</h3>
                <p className="text-gray-600">Your first modules are ready.</p>
              </div>
            </div>

            <div className="flex items-start gap-4">
              <div className="p-4 bg-purple-100 rounded-lg">
                <BookOpen className="w-8 h-8 text-purple-600" />
              </div>
              <div>
                <h3 className="text-2xl font-semibold">Structured Learning</h3>
                <p className="text-gray-600">Follow a proven curriculum.</p>
              </div>
            </div>

            <div className="flex items-start gap-4">
              <div className="p-4 bg-purple-100 rounded-lg">
                <Clock className="w-8 h-8 text-purple-600" />
              </div>
              <div>
                <h3 className="text-2xl font-semibold">Learn at Your Pace</h3>
                <p className="text-gray-600">Scheduled content releases.</p>
              </div>
            </div>

            <button
              onClick={handleStartLearning}
              className="w-full flex items-center justify-center gap-3 bg-purple-600 text-white py-4 px-8 rounded-lg hover:bg-purple-700 transition-all mt-8"
            >
              Start Learning Now
              <ChevronRight className="w-6 h-6" />
            </button>
          </div>
<div className="bg-gray-50 rounded-xl p-8">
  <h3 className="text-2xl font-semibold mb-6">What's Included:</h3>
  <ul className="space-y-5 text-lg text-gray-700">
    {includedItems.map((item, index) => (
      <li key={index} className="flex items-center gap-3">
        {item.icon}
        <span>{item.text}</span>
      </li>
    ))}
  </ul>
</div>
        </div>
      </div>
    </div>
  );
};

export default DripCourseWelcome;
