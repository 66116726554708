import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Shield, CheckCircle, ArrowLeft } from 'lucide-react';
import useApi from '../hooks/useApi';
import { useAuth0 } from '@auth0/auth0-react';
import logo from '../images/logo.png';

const UpgradePage = () => {
  const { courseId } = useParams();
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [courseDetails, setCourseDetails] = useState(null);
  const { fetchCourseDetail } = useApi();

  useEffect(() => {
    const loadCourse = async () => {
      try {
        const details = await fetchCourseDetail(courseId);
        console.log('Course details fetched:', details);
        setCourseDetails(details);
      } catch (err) {
        console.error('Failed to load course details:', err);
        setError('Failed to load course details');
      } finally {
        setLoading(false);
      }
    };
    loadCourse();
  }, [courseId, fetchCourseDetail]);

const handleUpgrade = async () => {
  try {
    setProcessing(true);
    setError(null);

    console.log('Starting upgrade process...');
    const token = await getAccessTokenSilently();

    const response = await fetch('/api/stripe/create-drip-upgrade-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ courseId }),
    });

    const data = await response.json();
    console.log('Upgrade session response:', data);

    if (data.error || !data.url) {
      throw new Error(data.error || 'Failed to retrieve session URL.');
    }

    // Redirect to the Stripe Checkout URL
//    window.location.href = data.url;
      window.location.replace(data.url);
  } catch (err) {
    console.error('Upgrade error:', err);
    setError('Failed to process upgrade. Please try again.');
  } finally {
    setProcessing(false);
  }
};

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <nav className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <img src={logo} alt="AI Guru Logo" className="h-8 w-auto" />
              <div className="hidden md:block ml-10">
                <button
                  onClick={() => window.history.back()}
                  className="flex items-center text-gray-600 hover:text-gray-900"
                >
                  <ArrowLeft className="w-5 h-5 mr-2" />
                  <span>Back to Course</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div className="max-w-4xl mx-auto py-12 px-4">
        <div className="text-center mb-12">
          <Shield className="w-16 h-16 text-purple-600 mx-auto mb-4" />
          <h1 className="text-3xl font-bold text-gray-900 mb-4">
            Upgrade to Premium Access
          </h1>
          <p className="text-xl text-gray-600">
            Get unlimited access to {courseDetails?.title}
          </p>
        </div>

        {error && (
          <div className="mb-6 p-4 bg-red-50 text-red-600 rounded-lg">
            {error}
          </div>
        )}

        <div className="bg-white rounded-2xl shadow-xl p-8">
          <div className="grid md:grid-cols-2 gap-8">
            <div>
              <h2 className="text-2xl font-semibold mb-6">What's Included</h2>
              <ul className="space-y-4">
                {[
                  'Full access to all course modules',
                  'Advanced tutorials and examples',
                  'Premium exercises and solutions',
                  'Course completion certificate',
                  'Access to future updates',
                ].map((benefit, i) => (
                  <li key={i} className="flex items-center gap-3">
                    <CheckCircle className="w-5 h-5 text-green-500 flex-shrink-0" />
                    <span>{benefit}</span>
                  </li>
                ))}
              </ul>
            </div>

            <div className="bg-gray-50 rounded-xl p-6">
              <div className="text-center mb-6">
                <div className="text-4xl font-bold text-gray-900">
                  ${courseDetails?.price ? courseDetails.price.toFixed(2) : '49'}
                </div>
                <div className="text-gray-600">One-time payment</div>
              </div>

              <button
                onClick={handleUpgrade}
                disabled={processing}
                className={`w-full bg-purple-600 text-white py-4 rounded-lg hover:bg-purple-700 transition-colors ${
                  processing ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                {processing ? 'Processing...' : 'Upgrade Now'}
              </button>

              <div className="mt-6 text-center">
                <div className="text-sm text-gray-500 mb-2">
                  Secure payment powered by Stripe
                </div>
              </div>
              <div className="mt-6 text-xs text-gray-500 text-center">
                By upgrading, you agree to our Terms of Service and Privacy Policy.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradePage;
