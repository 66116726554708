import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import {
  Lock, PlayCircle, BookOpen, Clock, CheckCircle, Calendar, AlertCircle,
  ChevronLeft, ChevronRight, ArrowLeft, Play, LayoutDashboard, User, Star
} from 'lucide-react';
import useApi from '../hooks/useApi';
import { useAuth0 } from '@auth0/auth0-react';
import logo from '../images/logo.png';

const DripContentViewer = () => {
  const { courseId, sectionIndex = 0, lectureId } = useParams();
  const [content, setContent] = useState(null);
  const [currentSection, setCurrentSection] = useState(parseInt(sectionIndex));
  const [currentLecture, setCurrentLecture] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [progressData, setProgressData] = useState({});
  const [videoProgress, setVideoProgress] = useState(0);
  const [isPremium, setIsPremium] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const videoRef = useRef(null);
  const scrollRef = useRef(null);  // Ref to track scrollable content
  const progressUpdateTimeout = useRef(null);
  const navigate = useNavigate();

  const { user, logout, isAuthenticated } = useAuth0();
  const {
    fetchDripCourseContent,
    fetchDripVideoUrl,
    updateVideoProgress,
    fetchCourseProgress,
    linkAccount
  } = useApi();


  const saveScrollPosition = () => {
    if (scrollRef.current) {
      localStorage.setItem('scrollPosition', scrollRef.current.scrollTop);
    }
  };

  const restoreScrollPosition = () => {
    const savedPosition = localStorage.getItem('scrollPosition');
    if (scrollRef.current && savedPosition) {
      scrollRef.current.scrollTo(0, parseInt(savedPosition));
    }
  };


  const loadContent = async () => {
    try {
      setLoading(true);
      const data = await fetchDripCourseContent(courseId);

      if (!data.content || !Array.isArray(data.content)) {
        throw new Error('Invalid content structure received from API');
      }

      // Transform the data to match expected structure
      const transformedData = {
        ...data,
        content: {
          sections: data.content
        },
        totalLectures: data.content.reduce(
          (sum, section) => sum + section.lectures.length,
          0
        ),
        courseTitle: data.content[0]?.title || 'Course Content'
      };

      setContent(transformedData);

      if (lectureId !== undefined) {
        const sectionData = transformedData.content.sections[parseInt(sectionIndex)];
        if (sectionData) {
          const lecture = sectionData.lectures[parseInt(lectureId)];
          if (lecture) {
            handleLectureSelect(parseInt(sectionIndex), lecture, parseInt(lectureId));
          }
        }
      }
    } catch (err) {
      console.error('Error loading drip content:', err);
      setError(err.message || 'Failed to load course content');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        localStorage.setItem('scrollPosition', scrollRef.current.scrollTop);
      }
    };
  
    if (scrollRef.current) {
      scrollRef.current.addEventListener('scroll', handleScroll);
    }
    
    restoreScrollPosition();
  
    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);
  


  useEffect(() => {
    loadContent();
  }, [courseId, sectionIndex, lectureId]);

  useEffect(() => {
    const loadProgress = async () => {
      try {
        const progress = await fetchCourseProgress(courseId);
        const progressMap = progress.reduce((acc, item) => {
          const lectureId = item.SK.split('#')[3];
          acc[lectureId] = item;
          return acc;
        }, {});
        setProgressData(progressMap);
      } catch (err) {
        console.error('Error loading progress:', err);
      }
    };

    loadProgress();
  }, [courseId, fetchCourseProgress]);

  useEffect(() => {
    const checkAndLinkAccount = async () => {
      const params = new URLSearchParams(window.location.search);
      const needsLinking = params.get('link');
      const email = params.get('email');

      if (needsLinking && isAuthenticated && email) {
        try {
          const result = await linkAccount(courseId);
          if (result.success) {
            const newUrl = window.location.pathname;
            window.history.replaceState({}, '', newUrl);
            await loadContent();
          }
        } catch (err) {
          console.error('Error linking account:', err);
          setError('Failed to link account. Please try again.');
        }
      }
    };

    checkAndLinkAccount();
  }, [isAuthenticated, courseId, linkAccount]);

  const updateProgress = async (timestamp, duration) => {
    if (progressUpdateTimeout.current) {
      clearTimeout(progressUpdateTimeout.current);
    }

    progressUpdateTimeout.current = setTimeout(async () => {
      try {
        const completed = timestamp >= duration * 0.9;
        await updateVideoProgress(courseId, currentLecture.id, {
          timestamp,
          totalDuration: duration,
          completed
        });

        setProgressData(prev => ({
          ...prev,
          [currentLecture.id]: {
            timestamp,
            totalDuration: duration,
            completed
          }
        }));
      } catch (err) {
        console.error('Error updating progress:', err);
      }
    }, 1000);
  };

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      const currentTime = videoRef.current.currentTime;
      const duration = videoRef.current.duration;
      const progress = (currentTime / duration) * 100;
      setVideoProgress(progress);
      updateProgress(currentTime, duration);
    }
  };

  const handleVideoLoad = () => {
    if (videoRef.current && currentLecture) {
      const savedProgress = progressData[currentLecture.id];
      if (savedProgress?.timestamp) {
        videoRef.current.currentTime = savedProgress.timestamp;
      }
    }
  };

  const getLectureProgress = (lecture) => {
    const progress = progressData[lecture.id];
    if (!progress) return 0;
    return (progress.timestamp / progress.totalDuration) * 100;
  };


  // 3. Update the DripContentViewer component's lecture status handling
  const getLectureStatus = (lecture) => {
    if (!lecture) return 'locked';

    console.log('Lecture status check:', {
      lecture_type: lecture.type,
      lecture_isUnlocked: lecture.isUnlocked,
      lecture_full: lecture
    });
    // Always unlock if lecture is marked as 'free'
    if (lecture.isUnlocked) {
      return 'unlocked';
    }
    switch (lecture.type) {
      case 'free':
        return 'unlocked';
      case 'drip':
        return lecture.isUnlocked ? 'unlocked' : 'locked';
      case 'premium':
        return isPremium ? 'unlocked' : 'premium';
      default:
        return 'locked';
    }

  };

  const renderLectureStatusIndicator = (status, releaseDate) => {
    switch (status) {
      case 'unlocked':
        return <PlayCircle className="w-5 h-5 text-green-600" />;
      case 'premium':
        return <Star className="w-5 h-5 text-amber-600" />;
      case 'scheduled':
        return (
          <div className="flex items-center">
            <Clock className="w-5 h-5 text-blue-600" />
            <span className="ml-2 text-sm text-blue-600">
              {new Date(releaseDate).toLocaleDateString()}
            </span>
          </div>
        );
      default:
        return <Lock className="w-5 h-5 text-gray-400" />;
    }
  };
  const handleLectureSelect = async (sectionIndex, lecture, lectureIndex) => {
    saveScrollPosition();
    console.log('Selecting lecture:', {
      sectionIndex,
      lecture,
      lectureIndex,
      contentType: lecture.contentType,
      videoKey: lecture.videoKey
    });

    const status = getLectureStatus(lecture);
    if (status === 'locked') {
      console.log('Lecture is locked, Triggering upgrade modal');
      setShowUpgradeModal(true);
      return;
    }
    if (status === 'premium' && !isPremium) {
      console.log('Premium content, showing upgrade modal');
      setShowUpgradeModal(true);
      return;
    }

    setCurrentLecture(lecture);
    setCurrentSection(sectionIndex);
    setVideoUrl(null); // Clear existing video URL

    navigate(`/courses/${courseId}/drip-content/${sectionIndex}/${lectureIndex}`, { replace: true });

    if (lecture.contentType === 'video' && lecture.videoKey) {
      try {
        console.log('Fetching video URL for:', lecture.videoKey);
        const response = await fetchDripVideoUrl(courseId, lecture.videoKey);
        console.log('Video URL response:', response);

        if (response && response.url) {
          console.log('Setting video URL:', response.url);
          setVideoUrl(response.url);
        } else {
          console.error('No URL in response:', response);
          setError('Failed to load video URL - No URL in response');
        }
      } catch (err) {
        console.error('Error fetching video URL:', err);
        setError('Failed to load video content');
      }
    }
  };

  const getLastWatchedLecture = () => {
    let lastWatched = null;
    let maxTimestamp = 0;

    Object.entries(progressData).forEach(([lectureId, progress]) => {
      if (progress.timestamp > maxTimestamp) {
        maxTimestamp = progress.timestamp;
        lastWatched = lectureId;
      }
    });

    if (lastWatched && content?.content?.sections) {
      // Find section and lecture index in the new schema
      for (let sIndex = 0; sIndex < content.content.sections.length; sIndex++) {
        const section = content.content.sections[sIndex];
        for (let lIndex = 0; lIndex < section.lectures.length; lIndex++) {
          if (section.lectures[lIndex].id === lastWatched) {
            return { sectionIndex: sIndex, lectureIndex: lIndex };
          }
        }
      }
    }

    return null;
  };

  const continueWatching = () => {
    const lastWatched = getLastWatchedLecture();
    if (lastWatched) {
      const section = content.content.sections[lastWatched.sectionIndex];
      const lecture = section.lectures[lastWatched.lectureIndex];
      handleLectureSelect(lastWatched.sectionIndex, lecture, lastWatched.lectureIndex);
    }
  };

  const handleBackToCourse = () => {
    //    navigate(`/courses/${courseId}/drip-content`);
    navigate(`/courses/${courseId}/drip-content`, { replace: true });
  };

  const UpgradeModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg p-8 max-w-md w-full mx-4">
          <h2 className="text-2xl font-bold mb-4">Upgrade to Premium</h2>
          <p className="text-gray-600 mb-6">
            Get unlimited access to all course content and premium features.
          </p>
          <div className="flex justify-end space-x-4">
            <button
              onClick={onClose}
              className="px-4 py-2 text-gray-600 hover:text-gray-800"
            >
              Cancel
            </button>
            <button
              onClick={() => navigate(`/courses/${courseId}/upgrade`)}
              className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
            >
              Upgrade Now
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderLectureItem = (lecture, sectionIndex, lectureIndex) => {
    let status = 'locked';

    if (lecture.isUnlocked) {
      status = 'unlocked';
    } else if (lecture.requiresUpgrade) {
      status = 'premium';
    }

    console.log('Rendering lecture:', {
      id: lecture.id,
      title: lecture.title,
      status
    });
    const isCurrentLecture = currentLecture?.id === lecture.id;

    return (
      <div key={lecture.id} className="relative">
        <button
          onClick={() => status !== 'locked' && handleLectureSelect(sectionIndex, lecture, lectureIndex)}
          disabled={status === 'locked'}
          className={`w-full px-6 py-3 flex items-center gap-3 transition-all
            ${status !== 'locked' ? 'hover:bg-purple-50 cursor-pointer' : 'opacity-60 cursor-not-allowed'}
            ${isCurrentLecture ? 'bg-purple-100 border-l-4 border-purple-600' : ''}`}
        >
          <div className={`p-2 rounded-lg ${status === 'unlocked' ? 'bg-green-100' :
              status === 'premium' ? 'bg-amber-100' :
                'bg-gray-100'
            }`}>
            {status === 'unlocked' ? (
              lecture.contentType === 'video' ? (
                <PlayCircle className="w-5 h-5 text-green-600" />
              ) : (
                <BookOpen className="w-5 h-5 text-green-600" />
              )
            ) : (
              <Lock className={`w-5 h-5 ${status === 'premium' ? 'text-amber-600' : 'text-gray-400'}`} />
            )}
          </div>

          <div className="flex-1 text-left">
            <div className={`font-medium ${status !== 'locked' ? 'text-gray-900' : 'text-gray-400'}`}>
              {lecture.title}
            </div>
            {lecture.duration && (
              <div className="text-sm text-gray-500 flex items-center mt-1">
                <Clock className="w-4 h-4 mr-1" />
                {lecture.duration}
              </div>
            )}
          </div>

          {status === 'premium' && (
            <span className="text-sm font-medium text-amber-600">
              Premium
            </span>
          )}
        </button>

        {status === 'unlocked' &&
          getLectureProgress(lecture) > 0 &&
          !progressData[lecture.id]?.completed && (
            <div className="absolute bottom-0 left-0 w-full h-1 bg-gray-200">
              <div
                className="h-full bg-purple-600 transition-all duration-300"
                style={{ width: `${getLectureProgress(lecture)}%` }}
              />
            </div>
          )}
      </div>
    );
  };

  const renderContent = () => {
    if (!currentLecture) return null;
    switch (currentLecture.contentType) {
      case 'video':
        if (error) {
          return (
            <div className="flex flex-col items-center justify-center h-64 bg-red-50 rounded-xl p-6">
              <AlertCircle className="w-12 h-12 text-red-500 mb-4" />
              <p className="text-red-600 text-center">{error}</p>
            </div>
          );
        }

        if (!videoUrl) {
          return (
            <div className="flex items-center justify-center h-64 bg-gray-50 rounded-xl">
              <div className="animate-spin rounded-full h-12 w-12 border-4 border-purple-600 border-t-transparent"></div>
            </div>
          );
        }

        return (
          <div className="aspect-video bg-black rounded-xl overflow-hidden shadow-2xl">
            <video
              ref={videoRef}
              controls
              className="w-full h-full"
              key={videoUrl}
              onTimeUpdate={handleTimeUpdate}
              onLoadedMetadata={handleVideoLoad}
              onError={(e) => {
                console.error('Video error:', e);
                setError('Failed to load video. Please try again.');
              }}
            >
              <source src={videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        );

      case 'quiz':
        return (
          <div className="p-8 bg-white rounded-lg shadow-md">
            <h2 className="text-xl font-semibold">Quiz Content</h2>
            {/* Add quiz component here */}
          </div>
        );

      default:
        return (
          <div className="p-8 bg-white rounded-lg shadow-md">
            <h2 className="text-xl font-semibold">Unsupported Content Type</h2>
            <p>This content type is not supported.</p>
          </div>
        );
    }
  };

  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="text-red-600 bg-red-50 p-4 rounded-lg">
          <AlertCircle className="w-6 h-6 mb-2" />
          <p>{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="h-screen flex flex-col bg-gray-50">
      {/* Top Navigation Bar */}
      <div className="bg-white border-b border-gray-200 px-4 h-16 flex items-center justify-between fixed w-full z-50">
        <div className="flex items-center space-x-4">
          <Link to="/" className="flex items-center">
            <img src={logo} alt="AI Guru Logo" className="h-8 w-auto" />
          </Link>

          {currentLecture && (
            <>
              <button
                onClick={handleBackToCourse}
                className="flex items-center text-gray-600 hover:text-gray-900"
              >
                <ArrowLeft className="w-5 h-5 mr-2" />
                <span>Back to Course</span>
              </button>
              <div className="h-6 w-px bg-gray-200 mx-4" />
            </>
          )}

          <h1 className="text-lg font-semibold text-gray-900 hidden md:block">
            {content?.courseTitle || 'Course Content'}
          </h1>
        </div>

        <div className="flex items-center space-x-4">
          <Link
            to="/dashboard"
            className="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg"
          >
            <LayoutDashboard className="w-5 h-5 mr-2" />
            <span>Dashboard</span>
          </Link>

          <div className="relative">
            <button
              onClick={() => setIsProfileOpen(!isProfileOpen)}
              className="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-100"
            >
              {user?.picture ? (
                <img src={user.picture} alt="Profile" className="w-8 h-8 rounded-full" />
              ) : (
                <User className="w-8 h-8 p-1 bg-gray-200 rounded-full" />
              )}
            </button>

            {isProfileOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 border border-gray-200">
                <div className="px-4 py-2 border-b border-gray-200">
                  <p className="text-sm font-medium text-gray-900">{user?.name}</p>
                  <p className="text-sm text-gray-500">{user?.email}</p>
                </div>
                <Link
                  to="/profile"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  Profile Settings
                </Link>
                <button
                  onClick={() => logout({ returnTo: window.location.origin })}
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  Sign Out
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Main Content Area */}
      <div className="flex pt-16 h-full">
        {/* Sidebar collapse button */}
        <button
          onClick={() => setSidebarCollapsed(!isSidebarCollapsed)}
          className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 bg-white p-2 rounded-r-lg shadow-md border border-l-0 border-gray-200"
        >
          {isSidebarCollapsed ? (
            <ChevronRight className="w-4 h-4" />
          ) : (
            <ChevronLeft className="w-4 h-4" />
          )}
        </button>

        {/* Left sidebar with collapsible animation */}
        <div className={`${isSidebarCollapsed ? 'w-0' : 'w-96'
          } bg-white overflow-y-auto border-r  border-gray-200 shadow-lg transition-all duration-300`}
          ref={scrollRef} >
          {!isSidebarCollapsed && (
            <>
              {/* Course progress header */}
              <div className="p-6 border-b border-gray-200 bg-gradient-to-r from-purple-500 to-indigo-600 text-white sticky top-0">
                <h2 className="text-xl font-bold mb-2">Course Progress</h2>
                <div className="mb-2">
                  <div className="flex justify-between text-sm mb-1">
                    <span>{content?.unlockedContent?.length || 0} lessons unlocked</span>
                    <span>{Math.round((content?.unlockedContent?.length || 0) / (content?.totalLectures || 1) * 100)}%</span>
                  </div>
                  <div className="w-full bg-white/20 rounded-full h-2">
                    <div
                      className="bg-white rounded-full h-2"
                      style={{ width: `${(content?.unlockedContent?.length || 0) / (content?.totalLectures || 1) * 100}%` }}
                    />
                  </div>
                </div>
                {content?.nextReleaseDate && (
                  <div className="flex items-center mt-4 bg-white/10 rounded-lg p-3 text-sm">
                    <Clock className="w-4 h-4 mr-2" />
                    <span>Next lesson unlocks in {new Date(content.nextReleaseDate).toLocaleDateString()}</span>
                  </div>
                )}
              </div>

              {/* Course sections */}
              <div className="pb-6">
                {content?.content?.sections?.map((section, sectionIndex) => (
                  <div key={sectionIndex} className="mt-6">
                    <div className="px-6 mb-2">
                      <h3 className="text-lg font-semibold text-gray-900">{section.title}</h3>
                    </div>
                    <div className="space-y-1">
                      {section.lectures.map((lecture, lectureIndex) =>
                        renderLectureItem(lecture, sectionIndex, lectureIndex)
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>

        {/* Main content area */}
        <div className="flex-1 overflow-y-auto">
          {currentLecture ? (
            <div className="h-full">
              {/* Content header */}
              <div className="p-6 bg-white border-b border-gray-200">
                <h1 className="text-2xl font-bold text-gray-900">{currentLecture.title}</h1>
                <div className="flex items-center mt-2 text-gray-600">
                  <Calendar className="w-4 h-4 mr-2" />
                  <span className="text-sm">Released {new Date().toLocaleDateString()}</span>
                </div>
              </div>

              {/* Content area */}
              <div className="p-6">
                {renderContent()}
              </div>
            </div>
          ) : (
            <div className="flex items-center justify-center h-full bg-gray-50">
              <div className="text-center max-w-md mx-auto p-8 bg-white rounded-xl shadow-lg">
                <PlayCircle className="w-16 h-16 text-purple-600 mx-auto mb-4" />
                <h2 className="text-2xl font-bold text-gray-900 mb-4">Welcome Back!</h2>
                <p className="text-gray-600 mb-6">
                  Continue your learning journey from where you left off, or start a new lesson.
                </p>
                {getLastWatchedLecture() ? (
                  <button
                    onClick={continueWatching}
                    className="flex items-center justify-center w-full bg-purple-600 text-white px-6 py-3 rounded-lg hover:bg-purple-700 transition-colors mb-4"
                  >
                    <Play className="w-5 h-5 mr-2" />
                    Continue Where You Left Off
                  </button>
                ) : null}
                {content?.content?.sections?.[0]?.lectures?.[0] && (
                  <button
                    onClick={() => handleLectureSelect(0, content.content.sections[0].lectures[0], 0)}
                    className="w-full bg-gray-100 text-gray-700 px-6 py-3 rounded-lg hover:bg-gray-200 transition-colors"
                  >
                    Start from Beginning
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Upgrade Modal */}
      <UpgradeModal
        isOpen={showUpgradeModal}
        onClose={() => setShowUpgradeModal(false)}
      />
    </div>
  );
};

export default DripContentViewer;
