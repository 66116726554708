import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { CheckCircle, Mail, Calendar, Clock, Info, ChevronRight } from 'lucide-react';
import useApi from '../hooks/useApi';

const SignupSuccess = () => {
  const location = useLocation();
  const { courseId } = useParams();
  const { email, existing } = location.state || {};
  
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { fetchCourseDetail } = useApi();

  useEffect(() => {
    const loadCourse = async () => {
      try {
        const fetchedCourse = await fetchCourseDetail(courseId);
        setCourse(fetchedCourse);
      } catch (err) {
        console.error('Error fetching course details:', err);
        setError('Failed to load course details.');
      } finally {
        setLoading(false);
      }
    };
    loadCourse();
  }, [courseId, fetchCourseDetail]);

  const handleNavigateToCourse = () => {
    window.location.href = course
      ? `/courses/${courseId}/`
      : '/courses';
  };

  if (loading) return <div className="text-center py-10">Loading course details...</div>;
  if (error) return <div className="text-center text-red-500">{error}</div>;
  if (!course) return <div className="text-center">Course not found.</div>;

  return (
    <div className="max-w-3xl mx-auto mt-16 p-10 bg-white rounded-xl shadow-lg text-center">
      <div className="mb-8">
        <CheckCircle className="w-24 h-24 text-green-500 mx-auto animate-pulse" />
      </div>

      <h1 className="text-4xl font-bold text-gray-900 mb-6">
        {existing ? 'Welcome Back!' : '🎉 Success!'}
      </h1>

      <p className="text-lg text-gray-600 mb-8">
        {existing
          ? `You're already enrolled in "${course.title}". Continue your learning journey below.`
          : `You're successfully enrolled in "${course.title}". A confirmation email has been sent to ${email}.`}
      </p>

      <div className="bg-purple-50 p-6 rounded-lg text-left mb-8">
        <h3 className="text-2xl font-semibold mb-3 flex items-center gap-3">
          <Mail className="w-7 h-7 text-purple-600" /> Email Confirmation
        </h3>
        <p className="text-gray-700">
          Check your inbox for course details. Content will be released based on the schedule. Look for email from 'noreply@beaiguru.com'
        </p>

        <h3 className="text-2xl font-semibold mt-6 mb-3 flex items-center gap-3">
          <Calendar className="w-7 h-7 text-purple-600" /> Drip Schedule
        </h3>
        <p className="text-gray-700">
          Your first module unlocks today, with future content released according to the course schedule.
        </p>

        <h3 className="text-2xl font-semibold mt-6 mb-3 flex items-center gap-3">
          <Clock className="w-7 h-7 text-purple-600" /> Learning Duration
        </h3>
        <p className="text-gray-700">
          Total course duration is approximately {course.duration}.
        </p>

        <h3 className="text-2xl font-semibold mt-6 mb-3 flex items-center gap-3">
          <Info className="w-7 h-7 text-purple-600" /> Instructor
        </h3>
        <p className="text-gray-700">{course.instructor}</p>
      </div>

      <button
        className="bg-purple-600 text-white py-4 px-10 rounded-lg hover:bg-purple-700 transition-all flex items-center justify-center gap-3"
        onClick={handleNavigateToCourse}
      >
        Go to Course Overview
        <ChevronRight className="w-6 h-6" />
      </button>

      <p className="mt-6 text-sm text-gray-500">
        Need assistance? <a href="/faq" className="text-purple-600 hover:underline">Visit our FAQ</a>
      </p>
    </div>
  );
};

export default SignupSuccess;
