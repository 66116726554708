import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

const faqs = [
  {
    question: 'How do I access my courses after enrolling?',
    answer: 'You can access your courses by logging into your account and going to "My Courses".',
  },
  {
    question: 'Can I download course materials?',
    answer: 'Videos are for streaming only. Some PDFs and resources are downloadable.',
  },
  {
    question: 'Do I get a certificate after completion?',
    answer: 'Yes, you will receive a certificate upon completing the course.',
  },
];

const FaqPage = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFaq = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="max-w-3xl mx-auto mt-16 p-10 bg-white rounded-xl shadow-lg">
      <h1 className="text-4xl font-bold text-center mb-10">Frequently Asked Questions</h1>
      {faqs.map((faq, index) => (
        <div key={index} className="border-b border-gray-200">
          <button
            className="w-full flex justify-between items-center py-6 text-lg font-medium text-left"
            onClick={() => toggleFaq(index)}
          >
            {faq.question}
            {openIndex === index ? (
              <ChevronUp className="w-6 h-6 text-purple-600" />
            ) : (
              <ChevronDown className="w-6 h-6 text-gray-500" />
            )}
          </button>
          {openIndex === index && (
            <div className="pb-6 text-gray-600">{faq.answer}</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FaqPage;
